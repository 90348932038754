<template>
  <b-container class='welcome text-center mt-1 pt-4 mb-4 box-wrapper'>
    <div class='welcome__title clearfix'>
      <p class='font-weight-bold'>{{ $t('welcome.title') }}</p>
    </div>
    <div class="main-form mt-4 ">
      <p class='font-weight-bold'>{{ $t('welcome.text') }}</p>
    </div>
    <b-button type='submit' class="welcome__button text-center mt-app__buttons mr-sm-5" variant="primary" @click="$router.push({ name: 'main-form' })">
        {{ $t('welcome.button-app-form') }}
    </b-button>
    <b-button type='submit' class="welcome__button text-center mt-app__buttons" variant="primary" @click="$router.push({ name: 'complaint-form' })">
        {{ $t('welcome.button-complaints-form') }}
    </b-button>
  </b-container>
</template>

<script>
export default {
  name: 'welcome'
}
</script>

<style lang='scss' scoped>
.welcome {
  &__title{
    font-size: 22px;
  }
  &__button{
    @media screen and (max-width: 575px) {
      margin: 1.5rem auto;
      width: auto;
    }
  }
}
</style>
